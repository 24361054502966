import { createAuth0 } from '@auth0/auth0-vue'
import { upperCaseAfterFirstDash } from '../utils'
import auth0js from 'auth0-js'
import store from '../store'
import i18n from '../translations'

export const auth0 = createAuth0({
    domain: `${process.env.VUE_APP_AUTH0_DOMAIN}`,
    clientId: `${process.env.VUE_APP_AUTH0_CLIENT_ID}`,
    authorizationParams: {
      audience: 'https://my.medel.com/api',
      redirect_uri: process.env.VUE_APP_AUTH0_HOME + '/callback'
    }
})

export function logout (language) {
  let optionalQueryString = language ? `?language=${language}` : ''
    store.commit('user/resetStore')
    auth0.logout({
      logoutParams: {
        returnTo: `${process.env.VUE_APP_AUTH0_HOME}/logout${optionalQueryString}`
      }
    })
}

export function login () {
  let language = i18n?.global?.locale

  let config = {
    appState: {
      target: '/callback'
    },
    fragment: `&lang=${language || 'en'}`,
    authorizationParams: {
      ui_locales: upperCaseAfterFirstDash(language || 'en')
    }
  }

  auth0.loginWithRedirect(config)
}

const auth0vanillajs = new auth0js.WebAuth({
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientID: process.env.VUE_APP_AUTH0_CLIENT_ID
})

export const startPasswordReset = function startPWReset (email, handler) {
  auth0vanillajs.changePassword({
    connection: process.env.VUE_APP_AUTH0_CONNECTION,
    email: email
  }, handler)
}
